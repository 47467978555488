import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import EmailContent from "./generateHtml";
import MuteButton from "./muteEventDrops1";

function OverlayDialog({
  localOverlayOpen,
  handleLocalOverlayClose,
  darkMode,
  event,
  filtersDocument,
  notesDocument,
  mainUser,
  early,
  totalEarly,
  isLowStock,
  vividIds,
  handleFilterClick,
  setCurrentEventId,
  setNotesModalOpen,
  classes,
}) {
  return (
    <Dialog
      open={localOverlayOpen}
      onClose={handleLocalOverlayClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          maxHeight: "100%",
          width: "90%",
          borderRadius: "20px",
          backgroundColor: darkMode ? "#222222" : "",
          color: darkMode ? "white" : "",
        },
        onClick: (e) => e.stopPropagation(),
      }}
      onDragStart={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogContent
        dividers
        style={{
          backgroundColor: darkMode ? "#222222" : "white",
        }}
        onDragStart={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <EmailContent
          event_info={{
            name: event.name,
            venue: event.venue,
            date: event.date,
            faceValueExchange: event.faceValueExchange,
            priceRange: event.priceRange,
          }}
          quantity={event.quantity}
          map_url={event.map_url}
          groupedTickets={event.groupTickets}
          eventLink={event.eventLink}
          timestamp={event.timestamp}
          filtersDocument={filtersDocument[event.id]}
          handleFilterClick={handleFilterClick}
          notesDocument={notesDocument}
          userEmail={mainUser}
          totalSeats={event.totalSeats}
          stubhub={event.stubhubId}
          eventId={event.id}
          early={early}
          totalEarly={totalEarly}
          showTable={true}
          editFilters={false}
          isLowStock={isLowStock}
          vividIds={vividIds}
          email={{
            ...event,
            ticketMasterUK: event.ticketMasterUK,
            eu: event.eu,
            eventId: event.id,
            eventUrl: event.eventLink,
            priceDrop: event.priceDrop,
            previousPrice: event.previousPrice,
            price: event.price,
            quantity: event.quantity,
            totalSeats: event.totalSeats,
            priceDrops: event.priceDrop,
          }}
          setCurrentEventId={setCurrentEventId}
          setNotesModalOpen={setNotesModalOpen}
          stubhubButton={(stubhubId) => (
            <div
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const stubhubUrl = `https://www.stubhub.com/event/${stubhubId}`;
                e.dataTransfer.setData("text/uri-list", stubhubUrl);
                e.dataTransfer.setData("text/plain", stubhubUrl);
              }}
              onClick={(e) => e.stopPropagation()}
              style={{ display: "inline-block" }}
            >
              <a
                href={`https://www.stubhub.com/event/${stubhubId}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.marketplaceButton}
                style={{
                  backgroundColor: "#3F1D74",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  userSelect: "none",
                }}
                onClick={(e) => e.stopPropagation()}
                draggable="true"
              >
                <img
                  src="/stubhubsmall.svg"
                  alt="stubhub-seats-logo"
                  width="20px"
                  height="19px"
                  style={{ pointerEvents: "none" }}
                />
              </a>
            </div>
          )}
          vividButton={(vividUrl) => (
            <div
              onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.dataTransfer.setData("text/uri-list", vividUrl);
                e.dataTransfer.setData("text/plain", vividUrl);
              }}
              onClick={(e) => e.stopPropagation()}
              style={{ display: "inline-block" }}
            >
              <a
                href={vividUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.marketplaceButton}
                style={{
                  backgroundImage: darkMode ? "url(/vivid-blue.svg)" : "none",
                  backgroundSize: "cover",
                  backgroundColor: darkMode ? "transparent" : "#000000",
                  width: "68px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  userSelect: "none",
                }}
                onClick={(e) => e.stopPropagation()}
                draggable="true"
              >
                <img
                  src="/vividsmall.svg"
                  alt="vivid-seats-logo"
                  width="20px"
                  height="17px"
                  style={{ pointerEvents: "none" }}
                />
              </a>
            </div>
          )}
        />
        <div style={{ display: "flex", marginTop: "-38px" }}>
          <MuteButton
            eventId={event.id}
            user={mainUser}
            eventUrl={event.eventLink}
            totalEarly={totalEarly}
            early={early}
            mainUser={mainUser}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default OverlayDialog;
